 .closeIcon{
     width:1.2rem;
     height: 1.2rem;
 }
 /* .modal-content{
  border-radius: 1rem;
} */
 .modal-content{
  border-radius: 1rem;
  color: var(--secondary-color);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

 /* Modal title */
.modal-title{
  font-size: 2rem !important; 
  letter-spacing: 0.5px;
  color: var(--secondary-color);
  font-weight: 700 !important;
}
.modal-body{
  margin: 50px 130px 50px 130px;
}
/* Modal header close button */
.modal-header button {
  font-size: 2.5rem;
}
/* Modal body conatiner */
.modal-body-container {
  /* margin: 2rem 5rem; */
  margin: 50px 130px 0px 130px;
}


/* classes for small modal */
.notification__popup{
  border-bottom: none !important;
}
.notification__popup > svg {
  font-size: x-large;
  font-weight: bold;
  color: var(--primary-color);
}
.modal-body-duration > p, .modal-body-description > p{
  font-size: medium;
  margin-bottom: 0.8rem;
}
/* Css for Notification popups */
.modal-footer__sm{
  border-top: none !important;
  margin: auto;
  text-align: center;
  display: block !important;
  padding: 0.75rem 0.75rem 1.75rem 0.75rem !important;
}
.notification-primary-btn{
  padding: 0.4rem 2rem;
  font-size: medium;
  margin-right: 0.5rem;
}
.modal-sm{
  margin-top: 5rem !important;
}


.time-slot{
  width: 12rem !important;
  margin-left: 0 !important;
  padding: 0.79rem !important;
  background-color: var(--light-grey);
  font-size: medium;
}
.btn-primary-outline{
  font-size: medium;
  padding: 0.3rem 2rem;
  color: var(--primary-color);
  background-color: var(--white-color);
  border: 1px solid var(--primary-color);
  font-weight: 500;
  /* border-radius: 15px; */
}
.modal-footer-link > a{
  text-decoration: underline;
  font-size: larger;
  font-weight: 700;
}
.btn-width{
  width: 20rem;
}
@media only screen and (max-width: 1024px) {
  .modal-body{
    margin: 0 !important;
  }
  .modal-title{
    font-size: 1.5rem !important;
  }
  .closeIcon{
    width:1rem !important;
    height: 1rem !important;
}

}